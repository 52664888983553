html {
	scroll-behavior: smooth;
}
.navbar a{
	text-decoration: none;
	color: white;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	font-size: 16px;
}
.navbar-toggler {
	border: none;
	background-color: transparent;
}
.navbar-toggler-icon {
	background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3E%3Cpath stroke="currentColor" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /%3E%3C/svg%3E');
	filter: invert(1);
}
.navbar-toggler {
	color: white;
}
.navbar-toggler:focus {
	outline: none;
}
.navbar-collapse .nav-item {
	color: white;
}
.navbar-collapse .nav-link {
	color: white !important;
}
.navbar-collapse .nav-link:hover {
	color: #ddd !important;
}
.navbar-collapse {
	display: none;
}
.navbar-collapse.show {
	display: block;
}
.bg-black {
	background-color: black;
}
.bg-green {
	background-color: #313131;
}
.bg-pink {
	background-color: #E73696;
}
.pink {
	color: #E73696;
}
.text-decoration-none {
	text-decoration: none;
}
.pointer {
	cursor: pointer;
}
.drop-line {
	margin-left: 80px;
}
h1, h5, h6, p, label {
	font-family: 'Playfair Display', serif;
}
h1 {
	font-weight: 700 !important;
	font-size: 35px !important;
	line-height: 46.66px !important;
}
h5 {
	font-weight: 600 !important;
	font-size: 24px !important;
	line-height: 31.33px;
}
h6 {
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 31.33px;
}
p, label {
	font-family: 'Roboto', sans-serif;
	font-weight: 400 !important;
	font-size: 16px !important;
}
.scroller-slider-controls {
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	padding-top: 8px;
}
.breadcrumb-item + .breadcrumb-item::before {
	color: white !important;
}
.guest-breadcrumb li a { 
	color: white;
}
ol li a {
	font-size: 18px;
	text-decoration: none !important;
}
.header-main {
	position: relative;
	height: 425px;
	background-size: cover;
	background-position: center;
	color: white;
	border: 0px;
}
.header-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.401);
	z-index: 1;
}
.header-container {
	position: relative;
	z-index: 2;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	text-align: center;
	color: white;
}
.header-contact {
	position: absolute;
	top: 20px;
	right: 10px;
	display: flex;
	flex-direction: row;
	gap: 20px;
	z-index: 2;
}
.section-contact {
	width: 200px;
	background-color: rgba(0, 0, 0, 0.152);
	color: white;
	padding: 10px;
	border-radius: 30px;
	border: 3px solid rgba(81, 0, 255, 0.6);
	box-shadow: 0 4px 8px rgba(251, 249, 255, 0.574);
}
.section-color {
	color: #FFAD33;
}
.header-contact p {
	margin: 0;
}
@media (max-width: 768px) {
	h1 {
		font-size: 28px !important;
		line-height: 38px !important;
	}
	h5 {
		font-size: 20px !important;
		line-height: 26.67px;
	}
	h6 {
		font-size: 14px !important;
		line-height: 20px;
	}
	p, label {
		font-size: 14px !important;
	}
	.scroller-slider-controls {
		display: block;
		text-align: center;
		padding-top: 16px;
	}
	.scroller-slider-controls img {
		display: inline-block;
		margin: 0 8px;
	}
	.header-main {
		height: 400px;
	}
	.header-contact {
		position: static;
		flex-direction: column;
		gap: 15px;
		width: auto;
		margin: 15px 0;
	}
	.section-contact {
		width: auto;
		padding: 8px;
		border-radius: 15px;
		border: 2px solid rgba(81, 0, 255, 0.6);
	}
}

@media (max-width: 480px) {
	h1 {
		font-size: 24px !important;
		line-height: 32px !important;
	}
	h5 {
		font-size: 18px !important;
		line-height: 24px;
	}
	h6 {
		font-size: 12px !important;
		line-height: 16px;
	}
	p, label {
		font-size: 12px !important;
	}
	.searchbar {
		width: 100%;
		padding: 5px;
		height: 45px;
	}
	.searchbar input {
		padding: 8px;
		margin-left: 5px;
	}
	.searchbar img {
		width: 20px;
	}
	.header-main {
		height: 300px;
	}
	.header-contact {
		margin: 10px 0;
	}
	.section-contact {
		width: auto;
		padding: 6px;
		border-radius: 10px;
	}
}
/* footer css */
.footer {
	background-image: url('assets/images/footer-bg.webp');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: white;
	border: 0;
	padding: 1rem 0;
}
.footer-logo {
	width: 100px;
	height: auto;
}
footer ul {
	max-width: 300px;
	list-style-type: none;
	padding: 0;
}
footer ul li a {
	text-decoration: none;
	color: white;
	font-family: 'Roboto', sans-serif;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 18px !important;
}
.footer .social-icons img {
	transition: transform 0.3s;
}
.footer .social-icons img:hover {
	transform: scale(1.1);
}
@media (max-width: 1200px) {
	.footer {
		padding: 2rem 1.5rem;
	}
	.footer-logo {
		width: 90px;
	}
}
@media (max-width: 992px) {
	.footer {
		padding: 2rem 1rem;
	}
	.footer .d-flex {
		flex-direction: column;
	}
	.footer-logo {
		width: 80px;
	}
	.social-icons img {
		width: 30px;
	}
	footer ul {
		max-width: none;
	}
}
@media (max-width: 768px) {
	.footer {
		padding: 1.5rem 1rem;
	}
	.footer-logo {
		width: 70px;
	}
	.social-icons img {
		width: 25px;
	}
	footer ul {
		text-align: left;
	}
	.footer .d-flex {
		flex-direction: column;
		align-items: flex-start;
	}
	.footer .container {
		text-align: left;
	}
	.footer .container > div {
		margin-bottom: 1rem;
	}
}
/*About page*/
/* General Styles */
.container-about-start, .container-about-mid, .about-end {
	padding: 50px 15px;
}
/* Background Images */
.container-about-mid {
	background-image: url('assets/images/background-image/about-mid-bg.webp');
	background-size: cover;
	background-position: center;
}
.about-end {
	background-image: url('assets/images/background-image/about-end-bg.webp');
	background-size: cover;
	background-position: center;
}
.accordion-button {
	color: white !important;
	background-color: #171818 !important;
	border: 0 !important;
}
.accordion-button:not(.collapsed) {
	box-shadow: none;
}
.accordion-collapse {
	background-color: #171818 !important;
}
.accordion-item {
	color: white !important;
	background-color: #171818 !important;
	border: 0 !important;
}
.custom-list {
	list-style: none;
	padding-left: 0;
}
.custom-list li {
	position: relative;
	padding-left: 25px;
}
.custom-list li::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 10px;
	height: 10px;
	background-color: blue;
	transform: translateY(-50%);
}
.about-cc {
	padding: 100px 15px;
}
.accordion-button::after {
	display: none;
}
.accordion-button.collapsed::before {
	content: '+';
	color: white !important;
	font-size: 1.25rem;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
}
.accordion-button:not(.collapsed)::before {
	content: '−';
	color: white !important;
	font-size: 1.25rem;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
}
.about-accordion {
	padding: 100px 15px;
}
/* Small devices (phones, 600px and down) */
@media (max-width: 600px) {
	.container-about-start, .container-about-mid, .about-end {
		padding: 30px 10px;
	}
	.about-cc {
		padding: 50px 10px;
	}
	.d-flex {
		flex-direction: column;
	}
	.d-flex > div {
		margin-bottom: 20px;
	}
	.custom-list li::before {
		background-color: blue;
	}
	.accordion-button::after {
		display: none;
	}
}
/* contact page  */
#contact-page .border-bottom-only {
	border-radius: 0;
	border: none;
	border-bottom: 1px solid #ced4da; 
	background-color: transparent;
	color: white;
}
#contact-page .border-bottom-only:focus {
	box-shadow: none;
	border-color: #80bdff;
	outline: none; 
	background-color: transparent;
}
#contact-page .contact-img{
	position: relative;
	overflow: hidden;
}
#contact-page .contact-img img{
	position: relative;
}
#contact-page .contact-form{
	margin: 50px 0px 50px 0px;
}
#contact-page input[type="text"], 
#contact-page input[type="email"], 
#contact-page input[type="tel"], 
#contact-page textarea {
	color: white !important;
}
#project-detail .table {
	--cui-table-bg: black !important;
	--cui-table-color: white !important;
}
#project-detail .main-image-container {
	text-align: center;
	margin-bottom: 15px;
}
#project-detail .main-image {
	width: 100%;
	max-height: 500px;
	object-fit: cover;
}
#project-detail .thumbnail-container {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}
#project-detail .thumbnail {
	width: 100px;
	height: 70px;
	margin: 0 5px;
	cursor: pointer;
	border: 2px solid transparent;
}
#project-detail .thumbnail.active {
	border-color: pink;
}
#project-detail .thumbnail:hover {
	border-color: #ddd;
}
#project-detail .table-container {
	margin-bottom: 20px;
}
#project-detail .table-transparent {
	background-color: transparent;
	color: #fff;
}
#project-detail .description {
	margin-top: 20px;
	font-size: 1.1rem;
}
#product-search .content {
	padding: 1rem;
}
#product-search .grid-view {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 1rem;
}
#product-search .product-item {
	display: flex;
	justify-content: center;
	align-items: center;
}
#product-search .loading {
	text-align: center;
	padding: 1rem;
}
#product-search .error {
	text-align: center;
	color: red;
	padding: 1rem;
}
#product-search .bg-green {
	background-color: #d4edda;
}
#product-search .rounded-4 {
	border-radius: 4px;
}
#product-search .my-2 {
	margin: 0.5rem 0;
}
@media (max-width: 767px) {
	.grid-view {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
}
#product-search .filter-container {
	width: 350px;
	padding: 1rem;
	background-color: #212529;
	border-radius: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	color: white;
}
@media (max-width: 576px) {
	#product-search .filter-container {
		width: 330px;
	}
}
#product-search .filter-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
#product-search .clear-all {
	text-decoration: none;
	cursor: pointer;
	color: #f8f9fa;
	font-weight: bold;
}
#product-search .separator {
	margin: 1rem 0;
	border: 1px solid #444;
}
#product-search .selected-categories, .all-categories, .price-range-container {
	margin-bottom: 1rem;
}
#product-search .section-title {
	font-weight: bold;
	margin-bottom: 0.5rem;
}
#product-search .category-item {
	margin: 0.5rem 0;
	display: flex;
	align-items: center;
	color: #f8f9fa;
}
#product-search .all-categories {
	display: flex;
	flex-direction: column;
}
#product-search .price-range-container {
	margin-top: 1rem;
}
#product-search .price-range-label {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	color: #f8f9fa;
}
#product-search .price-range-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.5rem;
	color: #f8f9fa;
}
#product-search .price-range-track {
	height: 6px;
	border-radius: 3px;
	background: #ddd;
	position: relative;
}
#product-search .price-range-thumb {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #548BF4;
	border: 2px solid #fff;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
	position: absolute;
	cursor: pointer;
}
#product-search .price-range-thumb.dragged {
	background: #3a86ff;
}
#product-search .price-range-thumb:focus {
	outline: none;
}
#product-search .toggle-container {
	display: inline-block;
	border-radius: 50px;
	overflow: hidden;
	background-color: #303335;
}
#product-search .toggle-options {
	display: flex;
	border-radius: 50px;
	overflow: hidden;
}
#product-search .toggle-option {
	flex: 1;
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px 10px 20px;
	cursor: pointer;
	color: #fff;
	background-color: #303335;
	border-radius: 50px;
	transition: background-color 0.3s, color 0.3s;
	box-sizing: border-box;
}
#product-search .toggle-option img {
	margin-right: 8px;
}
#product-search .toggle-option.active {
	background-color: #ff69b4;
	color: #fff;
	border-radius: 50px;
}
#product-detail {
	background-color: black;
	color: white;
}
#product-detail .main-image {
	width: 100%;
	display: flex;
	justify-content: center;
}
#product-detail .main-image img {
	width: 100%;
	height: 400px;
	object-fit: cover;
}
#product-detail .sub-images {
	width: 80%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
#product-detail .sub-image {
	width: 20%;
	height: auto;
	border: 1px solid #ddd;
	border-radius: 4px;
}
#product-detail .nav-link-btn {
	background-color: transparent;
	padding: 5px;
	color: white;
	border: 0;
	border-bottom: 3px solid transparent;
	transition: border-bottom 0.3s ease;
}
#product-detail .nav-link-btn.active-tab {
	border-bottom: 3px solid #E73696;
}
#product-detail .slick-slider button {
	display: none !important;
}
#product-detail .sub-slider .slick-slide {
	padding: 0 10px;
}
#product-detail .sub-slider .slick-slide img {
	border-radius: 4px;
}
#product-detail .slider-container {
	position: relative;
}
#product-detail .slider-controls {
	top: 50%;
	transform: translateY(-50%);
}
#product-detail .control-btn {
	width: 40px;
	transition: width 0.3s ease;
}
@media (max-width: 768px) {
	#product-detail .control-btn {
		width: 30px;
	}
}
@media (max-width: 576px) {
	#product-detail .control-btn {
		width: 25px;
	}
	#product-detail .slider-item img{
		width: 100px;
	}
}
#product-detail .btn-primary {
	background-color: #E73696;
	border-color: #E73696;
	transition: background-color 0.3s ease, border-color 0.3s ease;
}
#product-detail .btn-primary:hover {
	background-color: #E73696;
	border-color: #E73696;
}
#product-detail .suggested-products-slider .slick-list {
	width: auto !important;
}
#product-detail .suggested-products-slider .slick-track {
	width: auto !important;
}
#inquiry-modal input[type="text"],
#inquiry-modal input[type="email"],
#inquiry-modal input[type="tel"],
#inquiry-modal textarea[name="message"] {
	background-color: #D6D6D6;
	border: 1px solid #ced4da; 
}

#inquiry-modal input[type="text"]:focus,
#inquiry-modal input[type="email"]:focus,
#inquiry-modal input[type="tel"]:focus,
#inquiry-modal textarea[name="message"]:focus {
	background-color: #D6D6D6;
	border-color: #80bdff;
	box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .25);
}
#inquiry-modal .inquiry-form{
	margin-right: 15px !important;
}