/* about section css */
.about-container {
	padding: 2rem;
	background-color: #000;
}
.introduction-container {
	padding: 2rem;
	background-color: #222222;
	padding-top: 2rem;
}
.text-box {
	color: white;
	border: 2px solid #663eb8;
	border-radius: 65px;
	padding: 3rem;
	text-align: center;
}
.text-box-two {
	color: white;
	border: 2px solid #E73696;
	border-radius: 65px;
	padding: 3rem;
	text-align: center;
}
.inner-text-box {
	padding-left: 20px;
}
.image-box {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
}
.rounded-image {
	max-width: 85%;
	height: auto;
	border-radius: 15px;
}

/* Media Queries */
@media (max-width: 768px) {
	.about-container, .introduction-container {
		padding: 1rem;
	}
	.introduction-container {
		padding-top: 1rem;
	}
	.text-box, .text-box-two {
		padding: 2rem;
		border-radius: 30px;
	}
	.inner-text-box {
		padding-left: 10px;
	}
	.heading {
		font-size: 2rem;
	}
	.subheading {
		font-size: 1rem;
	}
	.paragraph, .paragraph-sm {
		font-size: 0.9rem;
	}
	.image-box {
		flex-direction: column;
		margin-bottom: 1rem;
	}

	button {
		font-size: 0.9rem;
		padding: 0.5rem 1rem;
	}
}

@media (max-width: 576px) {
	.text-box, .text-box-two {
		padding: 1rem;
		border-radius: 15px;
	}
	.inner-text-box {
		padding-left: 0;
	}
	.heading {
		font-size: 1.5rem;
	}
	.subheading {
		font-size: 0.9rem;
	}
	.paragraph, .paragraph-sm {
		font-size: 0.8rem;
	}
	.image-box {
		margin-bottom: 0.5rem; /* Add margin for separation */
	}

	button {
		font-size: 0.8rem; /* Adjust button font size */
		padding: 0.5rem 0.75rem; /* Adjust button padding */
	}
}

/*projects section css*/
.projects-container {
	position: relative;
	padding: 50px 50px;
}
.image-container {
	position: relative;
	margin: 0 10px;
}
.overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #e73696;
}
.arrow-icon {
	font-size: 16px;
}
.center-image {
	margin-top: 0;
	width: 100%;
}
.projects-container .d-flex {
	justify-content: center;
}
.projects-container .d-flex > div {
	margin-bottom: 20px;
}
.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.center-image-container {
	display: flex;
	justify-content: center;
	position: relative;
	width: 100%;
}
.center-image-container img {
	width: 100%;
	max-width: 600px; /* Adjust this to the desired maximum width */
	border-radius: 15px;
}

.overlay-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	text-align: center;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.6);
	padding: 5px 10px;
	border-radius: 5px;
}

.mid-text-relative {
	position: relative;
	width: 100%;
	height: 100%;
}

.mid-text-absolute {
	position: absolute;
	top: 50%;
	left: 40%;
	transform: translate(-50%, -50%);
	padding: 5px;
    background: #000000aa;
    padding-bottom: 0px;
}
.mid-text-right-absolute {
	position: absolute;
	top: 50%;
	left: 55%;
	transform: translate(-50%, -50%);
	padding: 5px;
    background: #000000aa;
    padding-bottom: 0px;
}

@media (max-width: 768px) {
	.projects-container {
		padding: 50px 20px;
	}
	.image-container {
		margin: 10px 0;
	}
	.rounded-image {
		width: 100%;
		height: auto;
	}
	.overlay {
		border: 2px solid #e73696;
		border-radius: 10px;
	}
	.center-image {
		margin-top: 50px;
		width: 100%;
	}
	.d-flex {
		flex-direction: column;
		align-items: center;
	}
	.d-flex > div {
		margin-bottom: 20px;
	}
}
/* products section css  */
#product-section .product-container{
	background-color: #222222;
	padding: 50px 0px 50px 0px;
}
#product-section .arrow-button-div {
	display: flex;
	justify-content: end;
	gap: 0.5rem;
	padding-top: 0.5rem;
}
#product-section .slick-slider button{
	display: none !important;
}
.image-wrapper {
	width: 100%; /* Adjust as necessary */
	height: 400px; /* Fixed height to handle large images */
	overflow: hidden; /* Hide any overflow */
	display: flex; /* Center the image */
	justify-content: center;
	align-items: center;
}

@media (max-width: 768px) {
	#product-section .arrow-button-div {
		display: flex;
		justify-content: center;
		gap: 0.5rem;
		padding-top: 0.5rem;
	}
}
@media (max-width: 480px) {
	#product-section .arrow-button-div img {
		width: 30px;
	}
}